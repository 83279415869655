/*NAVBAR*/

:root {
  --primary-color: #0038b1;
  --primary-color-hover: #1a59e0;
  --secondary-color: #64696f;
  --secondary-color-with-opacity: #bab4e21f;
  --background-color: #ebebf2;
  --mobile-background-color: #0b7abf;
  --mobile-text-link: #e5e5ec;
  --background-color-hover: #e5e5ec;
  --container-background-color: #fff;
  --green:#098a36;
  --green-2: #0fd655;
  --yellow: #ffce56;
  --yellow-hover: #ffb74b;
  --red: #ee315a;
  --red-hover: #dd0937;
  --text: #9792c7;
  --white: #fff;
  --black: #353535;
  --header-page: #0b7abf;
  --blue : #36a2eb;
  --avatar-size: 45px;
  --main-radius: 5px;
  --main-padding: 5px;
}
@import url('https://fonts.googleapis.com/css2?family=Francois+One&display=swap');

* {
  padding: 0;
  margin: 0;
  
}
body {
    margin: 0 auto;
}
::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 2px;
}

::-webkit-scrollbar
{
	width: 5px;
  height: 5px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-image: -webkit-gradient(linear,
									   left bottom,
									   left top,
									   color-stop(0.44, rgb(122,153,217)),
									   color-stop(0.72, rgb(19, 67, 126)),
									   color-stop(0.86, rgb(28,58,148)));
}

@media screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 1px;
  }

}


.container {
  display: flex;
  width:1440px;
  background: honeydew;
}

label {
  font-weight: bold;
  display: flex;
  margin: 0;
  margin-bottom: 6px;
  font-size: 1rem;
  cursor: pointer;

}

input + label {
  font-weight: bold;
  display: inline-flex;
  margin-right: 20px;
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'],
input[type='date'],
.input,
textarea,
select {
  display: block;
  width: 87%;
  padding: 7px 12px;
  font-size: 14px;
  line-height: 25px;
  color: #222;
  background-color: #fafafa;
  background-image: none;
  border: 1px solid grey;
  border-radius: 4px;
  /* margin-bottom: 20px; */
}

select {
  padding: 12px 12px;
  width: 89%;
}
@media screen and (max-width: 1300px) {
  select {
   width: 90%; 
  }

}

@media screen and (max-width: 1048px) {
  select {
   width: 92%; 
  }

}
@media screen and (max-width: 286px) {
  select {
   width: 100%; 
  }

}
input[type='radio']{
  /* min-width: 100px; */
  cursor: pointer;

}



.form-control {
  margin: 0 auto;
  min-width: 80%;
  width: 80%;

  margin-bottom: 15px;
  

}
.textarea {
  width: 100%;
  height: 50px;
}
.hidden {
  display: none;
}

.everyday-field {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
  margin: 0 auto;
}

.everyday-field h3 {
  margin-bottom: 15px;
  width: 100%;
  text-align: center;
  border-bottom: 2px solid var(--background-color);
  padding: 5px 0px;
  color: var(--black);

}

.everyday-field .header-everyday-field h4 {
  color: var(--black);

  padding-left: 10px;
}

.everyday-field .header-everyday-field .weekday {
  border-left: 2px solid green !important;
  
}

.everyday-field .header-everyday-field .weekend {
  border-left: 2px solid red !important;
  
}


.everyday-field .body-everyday-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 15px;
  

}
.everyday-field .body-everyday-field .day-field-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  text-align: center;
  width: 55px;
  
  /* background-color: #0038b1; */
  

}
.everyday-field .body-everyday-field div label {
  /* margin-top: 10px; */
  height: 20px;
  font-size: 12px;
}
.everyday-field .body-everyday-field div input {
  margin-top: 5px;
  
}
#default_late_tolerance::placeholder, #default_break_duration::placeholder {
  font-size: 8.5px;
  color: #acacac;
  
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}



.everyday-field .body-everyday-field div input::placeholder {
  font-size: 15px;
  color: black;
  
}



.error {
  color: red;
  font-size: .9rem;
}


.invalid-input {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: red;
}

.btnLoading {
  /* font-size: 15px !important; */
  background-color: gray !important;
  border: 1px solid gray !important;
}
.btnLoading:hover {
  cursor: not-allowed;
  color: #fff !important;
}

.table-control {
  margin: 25px 0;
  display: flex;
  color: #9792c7;
  /* background-color: wheat; */
  align-items: center;
  justify-content: space-between;

}

.table-control .limit {
  display: flex;
  align-items: center;
  font-size: 13.5px;
}

.table-control select {
  width: 65px;
  outline: none;
  border: none;
  background-color: #fcfcfc;
  margin: 0 5px;
  
}

.table-control .search input {
  width: 250px;
  height: 15px;
  background-color: transparent;
  border: none;
  border-radius: 0;
  outline: none;
  margin-left: -50px;
  border-bottom: 1.5px solid var(--primary-color)
}

.pagination {
  display: flex;
  margin-top: 25px;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 50px; */
}

.pagination .pagination-info {
  font-size: 13px;
  color: #9792c7;
}

.pagination .pagination-control {
  display: flex;
}

.pagination .pagination-control a, button {
  display: inline-block;
  padding: 7px 10px;
  color: #9792c7;
  font-size: 13px;
  /* padding: 8px 16px; */
  text-decoration: none;
  transition: background-color .3s;
  background: none;
  border: none;
  outline: none;
}
.pagination .pagination-control a:hover, button:hover {
  cursor: pointer;
}

.pagination a.active {
  background-color: var(--primary-color);
  color: white;
}

/* Add a grey background color on mouse-over */
.pagination a:hover:not(.active) {background-color: #ddd;}

.add-button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100px; */
  padding: 0 10px;
  font-size: 12px;
  height: 35px;
  color: #fff;
  border-radius: 5px;
  background-color: #48c774;
  cursor: pointer;
  text-decoration: none;
}
.print-button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100px; */
  padding: 0 10px;
  font-size: 12px;
  height: 35px;
  color: #fff;
  border-radius: 5px;
  background-color: #334257;
  cursor: pointer;
  text-decoration: none;
}
.print-button:hover {
  background-color: #476072;
}
.export-button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100px; */
  padding: 0 10px;
  font-size: 12px;
  height: 35px;
  color: #fff;
  border-radius: 5px;
  background-color: #141E61;
  cursor: pointer;
  text-decoration: none;
}

.export-button:hover {
  background-color: #0F044C;
}

.add-button:hover{
  background-color: #00e14f;

}
.add-bulk-schedule {
  padding: 0 20px;
  margin-left: auto;
  margin-right: 30px;
  margin-top: 20px;
}
.copy-button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100px; */
  font-size: 12px;
  height: 35px;
  color: #fff;
  border-radius: 5px;
  background-color: var(--header-page);
  cursor: pointer;
  text-decoration: none;
}

.copy-button:hover{
  background-color: var(--primary-color-hover);

}
.back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  font-size: 13px;
  height: 35px;
  color: #fff;
  border-radius: 5px;
  background-color: var(--black);
  cursor: pointer;
  text-decoration: none;
}

.back-button img {
  margin-left: -15px;
  width: 20px;
}

.back-button p {
  font-size: 14px;
  margin-left: 5px;
}
.back-button:hover{
  background-color: var(--secondary-color);


}
.edit-delete-wrapper{
  display: flex;

}
.detail-button{
  display: inline-block;
  margin-right: 4px;
  font-size: 12px;
  color: white;
  border-radius: 5px;
  background-color: #076ece;
}

.detail-button:hover {
  background-color: #2f96eb;
}
.primary-button{
  border-radius: 5px;
  margin-left: 2px;
  font-size: 11.5px;
  color: white;
  background-color: var(--primary-color);
  height: 30px;
  cursor: pointer;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-self: center;
  margin-right: 4px;

}

.primary-button:hover {
  background-color: var(--primary-color-hover);  
}
.delete-button{
  border-radius: 5px;
  margin-left: 2px;
  font-size: 12px;
  color: white;
  background-color: #dc3545;
  height: 30px;
  cursor: pointer;
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-self: center;

}

.delete-button:hover {
  background-color: #FF4136;  
}

.edit-button{
  border-radius: 5px;
  margin-right: 2px;
  font-size: 12px;
  color: var(--black);
  background-color: var(--yellow);

}

.edit-button:hover {
  background-color: var(--yellow-hover);  
}


.text-small {
  font-size: 13px;
  font-weight: 400;
  color: #acacac;
  letter-spacing: -.2px;
}

.border-right {
  border-right: 2px solid #acacac;
}

.text-big {
  font-size: 28px;
  font-weight: bold;
}

.text-big span {
  font-size: 13px;
  font-weight: 400;
}


.info-halaman {
  /* background-color: aqua; */
  display: flex;
  align-items: flex-end;
}


.info-halaman .info-waktu {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-self: center;
}
.info-halaman h3 {
  font-size: 24px;
}

.info-waktu p {
  padding-left: 20px;
}


.slide-container {
  width: 100%;
  height: 115px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
    /* background-color: #aaa; */
  width: 100%;
  box-sizing: border-box;

}
.slide-wrapper {
  
  padding-top: 20px !important;
  padding-bottom: 25px !important;
  padding-left: 50px !important;


}


.slide-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  border-radius: 7px;
  background-color: var(--white);
  height: 75px !important;
  width: 65px !important;
  border: 2px solid var(--black) !important;
}

.slide-item p {
  color: var(--black) !important;
}

/* .slide-item:first-child p, .slide-item:nth-child(2) p, .slide-item:nth-child(3) p {
  color: var(--white) !important;
  font-weight: bold;
  
} */
/* .slide-item:first-child img, .slide-item:nth-child(2) img, .slide-item:nth-child(3) img {
  border: 3px solid var(--white);
} */




.slide-item img {
  width: 45px;
  height: 45px;
  /* margin-left: 15px; */
  user-select: none;
  border-radius: 50%;
  box-sizing: border-box;
  border: 3px solid var(--secondary-color);
  padding: 3px;
}

.slide-item h3{
  font-size: 16px;
  color: var(--text);
  margin-left: 5px;
  margin-bottom: 10px;

}
.slide-item p {
  font-size: 12px;
  color: var(--text);
  /* margin-left: 15px; */

}

.slide-item img.first, .slide-item img.second, .slide-item img.third {
  border: 3px solid var(--white);
}

.slide-item p.first, .slide-item p.second, .slide-item p.third {
  color: var(--white);
}

.avatar-group {
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.hidden-avatars {
  width: var(--avatar-size);
  height: var(--avatar-size);
  border-radius: 50%;
  background-color: var(--black);
  border: 2px solid var(--black);
  color: var(--white);
  font-weight: bold;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -15px;
  margin-left: 3px;
}

.avatar {
  width: var(--avatar-size);
  height: var(--avatar-size);
  overflow: hidden;
  border-radius: 50%;
  background-color: var(--white);
  border: 2px solid var(--black);
  margin-left: -20px;
  position: relative;
  cursor: pointer;
  transition: transform .3s ease;
}
.small-avatar {
  width: 35px;
  height: 35px;
  overflow: hidden;
  border-radius: 50%;
  background-color: var(--white);
  border: 2px solid var(--black);
  position: relative;
  cursor: pointer;
  transition: transform .3s ease;
}
.small-avatar img {
  width: 35px;
  height: 35px;
  padding: 3px;
  box-sizing: border-box;
  border-radius: 50%; 

}
.avatar:hover:not(:last-of-type) {
  transform: translate(10px);
}
.avatar img {
  width: var(--avatar-size);
  height: var(--avatar-size);
  padding: 3px;
  box-sizing: border-box;
  border-radius: 50%; 

}

.wrapper-list {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.wrapper-list::-webkit-scrollbar {
  width: 3px;
}
.wrapper-list::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 0;
}

.ringkasan-list {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--background-color);
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}

.ringkasan-list h2 {
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 28px;
  width: 50px;
  height: 50px;
  background-color: var(--black);
  border-radius: 50%;
}
.ringkasan-list .detail-list {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.detail-list div {
  width: 75px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-weight: bold;
  margin: 0 5px;
}

.detail-list h4 {
  font-size: 22px;
  color: var(--black);
}

.detail-list p {
  box-sizing: border-box;
  padding: 3px 5px;
  font-size: 11px;
  width: 100%;
  text-align: center;
  border-radius: 3px;
}

.umum-jumlah p {
  background-color: var(--blue);
  width: 100%;
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis; 
}

.operasional-jumlah p {
  background-color: var(--green);
  width: 100%;
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis; 
}

.keuangan-jumlah p {
  background-color: var(--yellow);
  color: var(--black);
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis; 
}

.list-item {
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 8px;
  cursor: pointer;
  max-width: 100%;
  height: 50px;
  border-bottom: 1px solid var(--background-color);
  overflow: hidden; /* Mencegah konten melampaui batas elemen */
}

.text-overflow-tidak-hadir{
  white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    width: 150px;
}
@media screen and (max-width: 500px) {
  .text-overflow{
    white-space: nowrap;     
      overflow: hidden;
      text-overflow: ellipsis; 
      width: 250px;
  }
  .text-overflow-tidak-hadir{
    white-space: nowrap;     
      overflow: hidden;
      text-overflow: ellipsis; 
      width: 250px;
  }
}

@media screen and (max-width: 325px) {
  .text-overflow{
    white-space: nowrap;     
      overflow: hidden;
      text-overflow: ellipsis; 
      width: 170px;
  }
  .text-overflow-tidak-hadir{
    white-space: nowrap;     
      overflow: hidden;
      text-overflow: ellipsis; 
      width: 170px;
  }
}

.list-item:hover {
  background-color: var(--background-color);
}

.list-info {
  margin-left: 8px;
  flex-grow: 1; /* Membuat elemen ini mengambil ruang yang tersedia */
  overflow: hidden; /* Mencegah konten melampaui batas elemen */
}

.list-info h4 {
  font-size: 14px;
  white-space: nowrap; /* Mencegah teks membungkus ke baris berikutnya */
  overflow: hidden; /* Menyembunyikan teks yang melampaui lebar elemen */
  text-overflow: ellipsis; /* Menampilkan "..." di akhir teks yang terpotong */
}

.list-info p {
  font-size: 12px;
  color: var(--black);
  white-space: nowrap; /* Mencegah teks membungkus ke baris berikutnya */
  overflow: hidden; /* Menyembunyikan teks yang melampaui lebar elemen */
  text-overflow: ellipsis; /* Menampilkan "..." di akhir teks yang terpotong */
}

.list-info p span {
  font-size: 10px;
}

.list-right-info {
  margin-left: auto;
  margin-right: 8px;
  font-size: 11px;
  color: red;
  white-space: nowrap; /* Mencegah teks membungkus ke baris berikutnya */
  overflow: hidden; /* Menyembunyikan teks yang melampaui lebar elemen */
  text-overflow: ellipsis; /* Menampilkan "..." di akhir teks yang terpotong */
}

.modal-container {
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; */
  position: relative;
}

.modal-header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  height: 45px;
  max-width: 100%;
  align-items: center;
  background-color: var(--green);
  padding: 15px;
  padding-right: 10px;
  box-sizing: border-box;
  z-index: 99;

}
.modal-header .modal-title {
  color: var(--white);
  font-size: 17px;
}

.modal-header .close-modal {
  /* width: 30px; */
  /* height: 30px; */
  padding: 5px 10px;
  
  /* margin-right: 10px; */
  /* background-color: var(--red); */
  border-radius: 50%;
  color: var(--white);
  font-weight: bold;
  font-size: 16px;
}


.modal-header .close-modal:hover {
  background-color: var(--red);
  
}

.modal-body {
  /* background-color: var(--secondary-color); */

  /* grid-template-columns: repeat(auto-fit, 100px); */
  
  /* display: block; */

  /* margin-top: 50px; */
  /* width: 100%; */
  padding: 20px 0;
  
}

.modal-footer {
  width: 100%;
  height: 50px;
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 15px;
  /* padding-right: 45px; */
  box-sizing: border-box;
  background-color: var(--white);
  border-top: 1px solid var(--white)
}

.form-row {

  /* display: flex; */
  /* flex-direction: column; */
  display: grid;
  /* justify-content: center; */
  /* margin: 0 auto; */
  /* width: 100%; */
}

.form-login {
  width: 100%;
}

.form-login button {
  margin-left: 40px;
}

.tooltip {
  position: relative;
  display: block;
  text-align: center;
  /* border-bottom: 1px dotted black; */
  border: none;
}

.tooltip .tooltiptext {
  display: none;
  visibility: hidden;
  width: 140px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 120%;
  left: 40%;
  margin-left: -55px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  display: block;
}

.hasTooltip span {
  display: none;
  color: #000;
  text-decoration: none;
  padding: 3px;
}

.hasTooltip:hover span {
  display: block;
  position: absolute;
  background-color: #FFF;
  border: 1px solid #CCC;
  margin: 2px 10px;
}

.schedule-employee-name:hover {
  cursor: pointer;
  background-color: #222;
  color: white;
}

.schedule-employee-date:hover {
  cursor: pointer;
  background-color: #222;
  color: white;


}


.schedule-employee-date {
  max-width: 4px;
  /* overflow: auto; */
  text-align: center;
  padding: 10px 0;
  font-size: 11px;

}


.schedule-employee-date::-webkit-scrollbar {
  height: 2px;
}

.location-code {
  background-color: var(--red);

  color: #fff;
}
.shift-code {
  color: #fff;
  background-color: var(--green);

}


.copy-schedule {
  display: flex !important;
  max-width: 750px !important;
  align-items: flex-end;
  
}
.copy-schedule .form-control {
  margin: 0;
  min-width: 50px !important;
  width: 200px !important;
}
.copy-schedule .form-control input {
  padding: 5px;
  width: 175px !important;
}

.wrapper-ket-shift {
  margin-bottom: 10px;
  /* padding: 10px; */
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  padding-bottom: 15px;
  gap: 10px;
  padding-right: 10px;
  
}
.ket-shift {
  background-color: var(--background-color);
  border-radius: 4px;
  display: flex;
  padding: 7px;
  
  
}
.ket-shift .icon-ket-shift {
  background-color: var(--green);
  padding: 5px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}
.ket-shift .detail-ket-shift {
  
  margin-left: 7px;
  

}
.ket-shift .detail-ket-shift p {
  
  font-size: 12px;
  

}

.judul-ket-shift {
  font-size: 16px !important;
  margin-bottom: 5px;
  font-weight: bold;
}

.wrapper-ket-location {
  margin-bottom: 10px;
  padding: 10px;
  display: grid !important;
  /* grid-template-columns: repeat(4, 1fr);
   */
   grid-template-columns: repeat(auto-fit, minmax(100px, max-content));
  gap: 10px;
  padding-right: 10px;
  
}
.ket-location {
  /* background-color: #00e14f; */

  
}
.detail-ket-location {
  display: flex;
  align-items: center;
}
.judul-ket-location {
  background-color: var(--red);
  padding: 5px;
  margin-right: 5px;
  font-size: 13px;
  color: white;
  border-radius: 3px;
  
}

.schedule-month {
  width: 100%;
  /* text-align: center !important; */
  text-align-last:center;
  -moz-text-align-last: center;
  background-color: transparent;
  color: white;
  border: none;
  font-weight: bold;
  padding: 0;
}
.schedule-month:hover {
  cursor: pointer;

}

.schedule-month option:checked {
  font-weight: bold;
  color: black;

  
}


.schedule-month option:not(:checked) {
  color: black;

}

.schedule-month:focus{
  outline: none;

}

.minggu {
  background-color: var(--secondary-color);
  /* background-color: var(--red-hover); */
  color: white;
}

.hari-ini {
  background-color: var(--green);
  color: white;
}

.libur {
  background-color: var(--red-hover);
  color: var(--white);
}

.lewat {
  color: var(--background-color-hover);
  font-weight: normal;
}

.left {
  text-align: left;
}
.right {
  margin-left: auto;
  margin-right: 30px;
}

.keterangan-hari-libur {
  font-size: 14px;
}

.keterangan-hari-libur p {
  color: red;
  
}
.keterangan-hari-libur p span {
  padding-left: 5px;

}

.waktu-kerja-lewat {
  background: var(--green);
  animation: waktuKerjaLewat .5s infinite;
}

.waktu-kerja-dan-toleransi-lewat {
  background: var(--red);
  animation: waktuKerjaDanToleransiLewat .5s infinite;
}
@keyframes waktuKerjaLewat {
  from {background-color: var(--green);}
  to {background-color: var(--green-2);}
}

@keyframes waktuKerjaDanToleransiLewat {
  from {background-color: var(--red);}
  to {background-color: var(--red-hover);}
}

.sp-button {
  background-color: #098a36;
  color: white;
  font-size: 12px;
  border-radius: 5px;
  text-decoration: none;
  padding: 10px;

}
.sp-button:hover {
  background-color: #0fd655;
}

@media screen and (max-width: 500px) {
  .sp-buton {
    margin: 0 auto;
    width: 90%;
    text-align: center;
  }
  .back-button, .add-button {
    margin-left: 10px;

  }

}

.atur-cuti {
  color: black;
  text-align: center;
  padding: 5px 7px;
}
.atur-cuti:hover {
  cursor: pointer;
  background-color: #222;
  color: white;
}

.form {
  display: flex;
  /* background-color: #098a36; */

}

.left-form {
  flex: 1;
}

.right-form {
  flex: 1;
}


.save-button{
  border-radius: 5px;
  margin-right: 2px;
  margin-left: 10%;
  padding: 10px 22px;
  font-size: 12px;
  color: var(--black);
  background-color: var(--yellow);

}

.save-button:hover {
  background-color: var(--yellow-hover);  
}

.bpjs-tk, .bpjs-kes, .perhitungan-bpjs {
  font-size: 13px;
  margin-bottom: 20px;
}


.perhitungan-bpjs th {
  color: var(--black);
  background-color: var(--yellow);
}



.select-year {
  padding: 5px;
  width: 65px;
  height: 35px;
  outline: none;
  background-color: var(--black);
  color: white;
  text-align-last:center;
  -moz-text-align-last: center;
  border: none;
  font-weight: bold;
  appearance: none;

}

.select-year:hover {
  cursor: pointer;
  background-color: #4f4f4f;

}


.select-year option:checked {
  font-weight: bold;
  color: white;


  
}


.select-year option:not(:checked) {
  color: black;
  background-color: white;
  
}

.select-year:focus{
  outline: none;

}
.select-month {
  padding: 5px;
  width: 100px;
  height: 35px;
  outline: none;
  background-color: var(--black);
  color: white;
  text-align-last:center;
  -moz-text-align-last: center;
  border: none;
  font-weight: bold;
  appearance: none;

}


.select-month:hover {
  cursor: pointer;
  background-color: #4f4f4f;

}


.select-month option:checked {
  font-weight: bold;
  color: white;


  
}


.select-month option:not(:checked) {
  color: black;
  background-color: white;
  
}

.select-month:focus{
  outline: none;

}

.filter-group {
  background-color: var(--black);
  color: var(--white);
  font-size: 13px;
  padding: 5px;
  margin: 0 2px; 
  
}

.filter-group:hover{
  background-color: var(--primary-color);
  cursor: pointer;
}

.filter-group.active {
  background-color: var(--primary-color);
  
}

.accepted {
  background-color: var(--green);
}
.declined {
  background-color: var(--red);
}

.waiting {
  color: var(--black) !important;
  background-color: var(--yellow) !important;
}

.div-table {
  display: table;         
  width: auto;         
}
.div-table-row {
  display: table-row;
  width: auto;
  clear: both;
}
.div-table-col {
  float: left; /* fix for  buggy browsers */
  display: table-column;         
  width: auto;         
}

.leaflet-container {
  width: 100%;
  height: 100vh;
}

#attendance-map {
  width: 100%;
  height: 100vh;
}

@media (max-width: 768px) {
  #attendance-map {
    height: 50vh;
    z-index: 0;
  }
}

.wrapper-recap-report {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 50px;
  border: 1px solid var(--secondary-color);
}
.recap-report {
  flex: 1;
  
}
.header-recap-report {
  flex: 1;
  text-align: center;
  background-color: var(--yellow-hover);
  padding: 10px 0;
  font-weight: bold;
  color: white;
  font-size: 14px;
  border-bottom: 1px solid var(--secondary-color);
  
}



.body-recap-report {
  flex: 1;
  max-height: 350px;
  overflow: auto;
  
}
.body-recap-report div {
  display: flex;
  flex: 1;
  text-align: center;
  align-items: center;
  justify-content: center;
  min-height: 170px;
  font-size: 12px;
  
}


.body-recap-report div:nth-child(even){
  background-color: #f2f2f2;
  border: 1px solid black;
}
  
.body-recap-report div:hover {background-color: #ddd;}

.section-to-print {
  display: none !important;
}
.blanko {
  display: block !important;
}

@media print{

  body * {
    visibility: hidden;
  }

  .blanko {
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    width: 100% !important;
  }
  .blanko .logo {
    position: absolute !important;
    left: -100px;
  }

  .section-to-print, .section-to-print *, .print-table, .print-table *, .print-this, .print-this *, .blanko, .blanko * {
    visibility: visible !important;
    display: auto !important;
  }
  .no-print {
    display: none;
  }
  
  .section-to-print {
    display: block !important;
    position: relative !important;
    left: 0;
    top: 0;
  }
  .logo{
    left: 100px !important;
  }
  .header {
    display: flex !important;
    flex-direction: column !important;
    visibility: visible !important;
    position: absolute !important;
    width: 100% !important;
    left: 0 !important;
  }


  .print-table {
    position: absolute !important;
    width: 100%;
    left: 0;
    top: 155px;
    min-height: 500px;    

  }
  .print-this {
    position: absolute !important;
    width: 100%;
    left: 0;
    top: 155px;
    min-height: 500px;    

  }
  .footer {
    display: flex !important;
    margin-top: 50px !important;
  }

}

.ambil-foto-mobile{
display: none;
}

@media screen and (max-width: 500px) {
  .ambil-foto{
    display: none;
  }
  .ambil-foto-mobile{
  display: inline;
  }
}